const styles = (theme) => ({
  selectCourses: {
    width: "100%",
    height: "100%",
  },
  flexCentered: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    fontFamily: "Alenia",
    width: 200,
    height: 40,
    fontSize: 15,
    fontWeight: 600,
    marginTop: 20,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up("sm")]: {
      width: 400,
      height: 80,
      fontSize: 30,
    },
  },
  buttonDisabled: {
    fontFamily: "Alenia",
    width: 200,
    height: 40,
    fontSize: 15,
    fontWeight: 600,
    marginTop: 20,
    backgroundColor: "light-grey",
    color: theme.palette.common.white,

    [theme.breakpoints.up("sm")]: {
      width: 400,
      height: 80,
      fontSize: 30,
    },
  },
});
export default styles;
