import React from "react";
import HeaderSection from "../components/HeaderSection";
import Logo from "/public/assets/A-logo-primary.svg";
import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";


const SoldOut = ({ course, date, location }) => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="lg"
      style={{
        minHeight: "50vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(10),
      }}
    >
      <HeaderSection
        header="Our admissions are currently closed"
        subheader="We will be announcing new course dates shortly"
        location={location}
      />
      <Logo fill={theme.palette.primary.main} height={200} />


      {/* <CourseCards location={location} /> */}
    </Container>
  );
};

export default SoldOut;
