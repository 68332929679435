import React, { useState } from "react";
import ApplyForm from "../components/ApplyForm/ApplyForm";
import HeaderSection from "../components/HeaderSection";
import Container from "@material-ui/core/Container";
import Logo from "/public/assets/A-logo-primary.svg";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { encode } from "../functions/gdpr";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));



const ApplicationTemplate = ({ course, date, location }) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const [msg, setMsg] = useState();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const hubpotPromise = (formState) => {
    const hubspot = {
      fields: Object.keys(formState).map((key) => {
        return {
          name: key,
          value: formState[key],
        };
      }),
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Code Academy Berlin to store and process my personal data.",
        },
      },
    };
    return fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/8237502/80b92c37-31f8-44c8-8805-38502aab20e1`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(hubspot),
      }
    );
  };
  const cabAdminPromise = async (formState) => {
    const { strapi } = await encode(formState);
    const data = JSON.stringify(strapi, null, 2);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${process.env.GATSBY_STRAPI_TOKEN}`
      },
      body: data
    };
    return fetch(
      `${process.env.GATSBY_STRAPI_URL}/api/leads`,
      requestOptions
    );
  }
  const submitApplication = async (formState) => {

    setLoading(true);
    try {
      const resp = await hubpotPromise(formState);
      if (resp) {
        setLoading(false);
        if (resp.ok) {
          setMsg({ header: "Application Submitted", subheader: "Thanks for your interest. We will get back to you shortly" });

          // data layer used for google analytics event tracking
          // window.dataLayer = window.dataLayer || [];

          if (window?.gtag) {
            window.gtag({
              'event': 'generate_lead',
              'send_to': 'G-0P579Z5MZR',
              'plan': formState?.course ?? "",
            });
            window.gtag('event', 'conversion', { 'send_to': 'AW-597839153/N26QCIfqiLkZELGaiZ0C' });
          }

        } else
          setMsg({
            header: "Application Submission Failed",
            subheader: "There was an issues submitting your application. Please contact us at info@codeacademyberlin.com",
          });
        setIsSubmited(true);
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setLoading(false);
      await cabAdminPromise(formState);
    }
  };


  return (
    <>
      <Helmet>
        {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> */}

      </Helmet>

      <Container
        maxWidth="lg"
        style={{
          minHeight: "50vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(4),
        }}
      >

        {loading ? (
          <CircularProgress />
        ) : (
          <React.Fragment>
            {isSubmited ? (
              <Container
                maxWidth="lg"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: theme.spacing(4),
                }}
              >
                <Logo fill={theme.palette.primary.main} height={200} />
                <HeaderSection
                  header={msg.header}
                  subheader={msg.subheader}
                  location={location}
                />
                <Grid container spacing={3}>
                  {/* <Grid item xs={12}>
                    <Typography component="h5" variant="h5" align="center">
                      Meanwhile, have a look at our Syllabus:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      href="\files\Web-Development-Syllabus-2.pdf"
                      download="Syllabus Web Development"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      underline="none"
                    >
                      <Button
                        underline="none"
                        name="course"
                        value="web"
                        className={classes.button}
                      >
                        Web Development
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      href="\files\Data-Science-Syllabus-2.pdf"
                      download="Syllabus Data Science"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      underline="none"
                    >
                      <Button
                        underline="none"
                        name="course"
                        value="data"
                        className={classes.button}
                      >
                        Data Science
                      </Button>
                  
                    </Link>
                  </Grid> */}
                </Grid>
              </Container>
            ) : (
              <React.Fragment>
                <ApplyForm
                  location={location}
                  course={course}
                  date={date}
                  submit={submitApplication}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}

      </Container>
    </>
  );
};
export default ApplicationTemplate;
