import React from "react";
import Layout from "../../components/Layout";
import ApplicationTemplate from "../../templates/application-page";
import ApplicationSoldOutTemplate from "../../templates/application-page-soldout";
import SEO from "../../components/SEO";
const Index = ({ location }) => {
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const course = urlParams.get("course");
  const date = urlParams.get("date");
  return (
    <Layout location={location} title="Apply">
      <SEO
        title={"Visit Code Academy Berlin"}
        location={location}
        description={"Come to an open visit to our coding school"}
      />
      <ApplicationTemplate location={location} course={course} date={date} />
      {/* <ApplicationSoldOutTemplate location={location} course={course} date={date} /> */}
    </Layout>
  );
};
export default Index;
